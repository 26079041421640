<template>
  <div style="width: 100%">
    <el-table border size="mini" :data="datas" style="width: 100%">
      <el-table-column
        fixed="left"
        prop="Member.Code"
        label="客户编号"
        width="120"
      >
      </el-table-column>
      <el-table-column prop="Member.Name" fixed="left" label="姓名"  min-width="120">
      </el-table-column>
      <el-table-column prop="Member.Mobile" label="手机号" width="100">
      </el-table-column>
      <el-table-column prop="Member.Sex" label="性别" width="60">
      </el-table-column>
      <el-table-column prop="Member.Birthday" label="年龄" width="60">
        <template slot-scope="scope">
          <span>{{
            format.getAgeByDateString(format.dateFormat(scope.row.Member.Birthday))
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="运动习惯" header-align="center">
        <el-table-column
          show-overflow-tooltip
          label="日常运动"
          :min-width="150"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.Exercise"
              >{{ scope.row.Exercise }}/{{ scope.row.ExerciseAmount }}min/次/{{
                scope.row.ExerciseFrequency
              }}次/周</span
            >
            <span v-else> </span>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="JoinDangerExercise"
          label="高危运动"
          width="100"
        >
          <template slot-scope="scope">
            <el-tag v-if="scope.row.JoinDangerExercise" type="danger"
              >参加</el-tag
            >
            <el-tag v-else type="success">不参加</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="Exercise"
          show-overflow-tooltip
          label="跑步成绩"
          :min-width="150"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.RunType"
              >{{ format.runTypeFormat(scope.row.RunType) }}/{{
                scope.row.RunScore
              }}</span
            >
            <span v-else>不跑步</span>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="生活习惯" header-align="center">
        <el-table-column
          show-overflow-tooltip
          prop="SmokeLength"
          label="吸烟情况"
          :min-width="150"
        >
          <template slot-scope="scope">
            <el-tag v-if="scope.row.SmokeLength" type="danger"
              >吸烟{{ scope.row.SmokeLength }}年/每天{{
                scope.row.SmokeDayCount
              }}支/已戒{{ scope.row.SmokeStopLength }}年</el-tag
            >
            <el-tag v-else type="success"> 不吸烟 </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="DrinkWine"
          show-overflow-tooltip
          label="饮酒情况"
          :min-width="150"
        >
          <template slot-scope="scope">
            <el-tag v-if="scope.row.DrinkWine" type="danger"
              >喝酒{{ scope.row.DrinkWine }}年/每天{{
                (
                  (scope.row.DrinkWeekCount * scope.row.DrinkWineAmount) /
                  7
                ).toFixed(1)
              }}两/已戒{{ scope.row.DrinkStopLength }}年</el-tag
            >
            <el-tag v-else type="success"> 不喝酒 </el-tag>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="疾病及用药" header-align="center">
        <el-table-column
          show-overflow-tooltip
          prop="HealthStatus"
          label="目前症状"
          :min-width="150"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="MedicalHistory"
          label="既往病史"
          :min-width="150"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="Inheritance"
          label="遗传疾病"
          :min-width="150"
        >
        </el-table-column>
      </el-table-column>
      <el-table-column prop="InsertTime" label="自测时间" width="120">
        <template slot-scope="scope">
          {{ format.dateTimeFormat(scope.row.InsertTime) }}
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="pagination"
      background
      @size-change="pageSizeChange"
      @current-change="pageCurrentChange"
      :page-sizes="pageSizeList"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      :current-page.sync="pageIndex"
    ></el-pagination>
  </div>
</template>
  <script>
import { getPageList, save, get } from "@/api/check/checkSelf.js";
export default {
  components: {  },
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      default: "",
    },
    filter: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    filter: {
      handler(val) {
        this.pageIndex = 1;
        this.getData();
      },
    },
  },
  data() {
    return {
      datas: [],
      pageSize: 10,
      pageIndex: 1,
      total: 0,
      pageSizeList: [10, 20, 30, 40, 50],
      queryType: "Heart",
    };
  },

  methods: {
    getData() {
      this.filter.pageIndex = this.pageIndex;
      this.filter.pageSize = this.pageSize;
      getPageList(this.filter).then((res) => {
        this.datas = res.Items;
        this.total = res.Total || res.Items.length;
      });
    },
    create() {
      this.$refs.heartEditor.handleCreate();
    },
    update(row) {
      let json = JSON.parse(JSON.stringify(row));
      this.$refs.heartEditor.handleEdit(json);
    },
    pageSizeChange(val) {
      var pageSize = `${val}`;
      this.pageIndex = 1;
      this.pageSize = parseInt(pageSize);
      this.$nextTick(() => this.getData());
    },
    pageCurrentChange(val) {
      this.pageIndex = `${val}`;
      this.getData();
    },
  },
  mounted() {},
};
</script>
  <style lang='scss' scoped>
.status {
  align-self: flex-end;
  font-size: 12px;
  font-weight: bold;
  color: #ff862f;
}
.statusdesc {
  align-self: flex-end;
  font-size: 12px;
  color: #797878;
}
</style>